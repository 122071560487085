import React, { useState, useEffect } from "react"
import { storyblokEditable, useStoryblokState } from "gatsby-source-storyblok"
import get from 'lodash/get'

import { Link } from 'gatsby'

import Author from './Author'

import Image from "utils/Image"
import '../../static/styles/post-preview.css'

export default ({ node }) => {
  const [previewStory, setPreviewStory] = useState(null);

  let story = useStoryblokState(node, {
    resolveRelations: ["post.author", "post.categories"]
  });

  // useEffect(() => {
  //   if (window.location.search.includes('_storyblok')) {
  //     setPreviewStory(null);
  //   }
  // }, [story]);

  // useEffect(() => {
  //   if (window.location.search.includes('_storyblok')) {
  //     const url = `https://api-us.storyblok.com/v2/cdn/stories/${node.full_slug}?version=draft&token=${process.env.GATSBY_STORYBLOK_TOKEN}&cv=${Date.now()}&resolve_relations=post.author,post.categories`;

  //     const fetchData = async () => {
  //       try {
  //         const response = await fetch(url);
  //         const json = await response.json();
  //         setPreviewStory(json.story);
  //       } catch (error) {
  //         console.log("error", error);
  //       }
  //     };

  //     fetchData();
  //   }
  // }, []);

  const content = previewStory ? previewStory.content : story.content;

  return (
    <div
      id={node.slug}
      className="post-preview regular-post-card regular-post-card"
    >
      <div className="row h-100">
        <div className="col-12 col-md-4">
          <div className="post-img-container h-100">
              <div
                style={{
                  height: '100%',
                  minHeight: '169px',
                  position: 'relative',
                  display: 'flex',
                  flexDirection: 'row !important',
                  backgroundClip: 'border-box',
                }}
              >
            <Link to={`/${node.slug}`}
              aria-label={`Read more about ${get(node, 'name')}`}
              >
                {content.hero ? (
                  <div className="image-wrapper">
                    <Image img={content.hero} heightRatio={0.53} sizes="(min-width: 768px) 250px, 95vw" />
                  </div>
                ) : content.cover ? (
                  <div className="image-wrapper">
                    <Image img={content.cover} heightRatio={0.53} sizes="(min-width: 768px) 250px, 95vw" />
                  </div>
                ) : (
                  ''
                )}
            </Link>
              </div>
          </div>
        </div>
        <div className="col-12 col-md-8">
          <div className="h-100 d-flex flex-column">
            <div className="preview-contents flex-grow-1 d-flex flex-column">
              <div className="pre-title-group">
                <span className="date float-right">
                  {content.published_date
                    ? new Date(content.published_date).toLocaleString(
                      'en-US',
                      {
                        year: 'numeric',
                        month: 'long',
                        day: 'numeric',
                      }
                    )
                    : node.published_at}
                </span>

                {content.categories &&
                  content.categories.slice(0, 3).map((category) => (
                    <span
                      className="post-category d-inline-flex"
                      key={category.slug}
                    >
                      {category.name}
                    </span>
                  ))}
              </div>
              <strong>
                <Link to={`/${node.slug}`}>{get(node, 'name')}</Link>
              </strong>
              {/* <div className="flex-grow-1">
            <p
              dangerouslySetInnerHTML={{
                __html: content.description,
              }}
            />
            <Link to={`/${node.slug}`} className=''link arrow-link>
              Continue Reading
            </Link>
          </div> */}
              <div
                style={{
                  margin: 'auto 0 0',
                }}
              >
                <Author node={content.author} />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
