import React, { useEffect, useState } from 'react'
import { renderToString } from 'react-dom/server';
import RichTextRenderer from 'utils/RichTextRenderer'

export default function PostContent({ content, cta_content = {} }) {
  const [postContent, setPostContent] = useState()
  
  const createCTA = (className = '') => {
    let { body, button_text, url } = cta_content
    body = body && renderToString(<RichTextRenderer data={body} />)

    if (!body || body === '' || body === '<div data-reactroot=""></div>') {
      body = `
      <p class='headline'>
        <span>2X your replies</span> with a single line of copy
      </p>
      <p class='content'>
        Join our mailing list and get 98 call-to-action templates that got us results over the years.
      </p>`
    }
    if (!button_text || button_text === '') {
      button_text = 'GET MORE REPLIES'
    }
    if (!url || url === '') {
      url = '/cta-swipe-file'
    }
    return `<div class='box-cta' ${className} >
              ${body}
              <div class='cta-link'>
                <a href=${url} class="btn btn-primary purple-btn" rel="noreferrer" target="_blank">
                  ${button_text}
                </a>
              </div>
            </div>`
  }

  useEffect(() => {
    content = renderToString(
      (<RichTextRenderer data={content} />)
    )
    let matches = [...content.matchAll(/<h2/g)]
    if (matches.length > 2) {
      const cta = createCTA()
      var editedPost =
        content.slice(0, matches[2].index) +
        cta +
        content.slice(matches[2].index)
    } else {
      const cta = createCTA("class='d-none d-xl-flex'")
      var editedPost = content + cta
    }
    setPostContent(editedPost)
  }, [])

  return (
    !postContent ? 
      <div className="post-content">
        <RichTextRenderer data={content} />
      </div>
      :
      <div
        className="post-content"
        dangerouslySetInnerHTML={{ __html: postContent }}
      />
  )
}
