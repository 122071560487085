import React from 'react'

import Author from './Author'
import PostPreview from './PostPreview'
import CourseBanner from './CourseBanner'
import PostContent from './PostContent'
import Image from "utils/Image"
import '../../static/styles/cta-box.css'
import '../../static/styles/blog-post.css'
import { storyblokEditable } from "gatsby-source-storyblok"
import FooterBanner from '../components/FooterBanner'

export default ({
  post,
  content,
  author,
  categories,
  previous,
  next,
  breadcrumbItems,
}) => {
  const showHero =
    content.hero && (typeof content.content === 'string' && !content.content.split('</')[0].includes('<img'))

  const cta_content = {
    body: content.cta_body,
    button_text: content.cta_button_text,
    url: content.cta_url,
  }

  return (
    <>
      <div {...storyblokEditable(content)} className="container blog-post-container p-relative">
        <section className="row ">
          <div
            className="blog-post order-1 col-12 col-lg-12 col-xl-12"
            style={{
              paddingBottom: '46px',
            }}
          >
            {/* <Breadcrumb items={breadcrumbItems} /> */}
            {categories && categories.length > 0 && (
              <div className="categories" style={{ marginTop: '5px' }}>
                {categories.map((category) => (
                  <span
                    className="post-category d-inline-flex"
                    key={category.slug}
                  >
                    {category.name}
                  </span>
                ))}
              </div>
            )}
            <h1>{post.name}</h1>
            <div className="d-flex justify-content-between align-items-center">
              <Author node={author} />
              <span className="date">
                {(post.modified_at &&
                  post.modified_at != 'March 15, 2021' &&
                  post.modified_at != post.created_at) ?
                    <span>
                      <b>Updated: {post.modified_at}</b>
                    </span>

                  :
                  content.published_date ?
                    new Date(content.published_date).toLocaleString('en-US', {
                      year: 'numeric',
                      month: 'long',
                      day: 'numeric',
                    })
                    :
                    post.created_at
                }
              </span>
            </div>
          </div>
          <div className="blog-post order-2 col-12 col-lg-10 col-xl-8">
            {showHero && (
              <div className="image-wrapper post-hero">
                <Image img={content.hero} alt={post.name} />
              </div>
            )}

            <PostContent content={content.content} cta_content={cta_content} />
          </div>
          <div className="side-banners order-4 order-xl-3 col-12 col-lg-10 col-xl-4 p-relative">
            <div className="side-banner sticky-banner">
              <CourseBanner />
            </div>
          </div>
          <div className="blog-post social-group order-3 order-xl-4 col-12 col-lg-10 col-xl-8">
            <hr />
          </div>
          <div className="featured-posts order-5 col-12 col-lg-10 col-xl-8">
            <p className="like-h4">Want to learn more?</p>
            <ul
              style={{
                padding: 0,
                margin: 0,
                listStyle: 'none',
              }}
            >
              {previous && (
                <li className="featured-post-preview">
                  <PostPreview node={previous} />
                </li>
              )}
              {next && (
                <li className="featured-post-preview">
                  <PostPreview node={next} />
                </li>
              )}
            </ul>
          </div>
        </section>
      </div>
      <FooterBanner />
    </>
  )
}
