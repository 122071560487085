import React, { useState, useEffect } from "react"
import { Helmet } from 'react-helmet'
import { graphql } from 'gatsby'
import get from 'lodash/get'
import BlogPost from '../components/BlogPost'
import { useStoryblokState } from "gatsby-source-storyblok"
import Seo from "components/Seo"

const BlogPostTemplate = ({
  data: {
    post,
    posts
  },
  pageContext: {
    next,
    previous
  },
  path,
}) => {
  // This will be used to set live editor preview data
  const [previewStory, setPreviewStory] = useState(null);

  // Parse Storyblok data
  let story = useStoryblokState(post, {
    resolveRelations: ["post.author", "post.categories"]
  });

  // I can't remember why this was needed, so let's first test the Storyblok editor experience with it and then without it
  useEffect(() => {
    if (window.location.search.includes('_storyblok')) {
      setPreviewStory(null);
    }
  }, [story]);

  // If inside Storyblok editor, execute this
  useEffect(() => {
    if (window.location.search.includes('_storyblok')) {

      // Storyblok API endpoint
      const url = `https://api-us.storyblok.com/v2/cdn/stories/${post.full_slug}?version=draft&token=${process.env.GATSBY_STORYBLOK_TOKEN}&cv=${Date.now()}`;

      // Fetch preview data from Storyblok API
      const fetchData = async () => {
        try {
          const response = await fetch(url);
          const json = await response.json();
          setPreviewStory(json.story);
        } catch (error) {
          // console.log("error", error);
        }
      };

      fetchData();

      // Let's wait until the Storyblok script has loaded
      const waitForStoryblokToLoad = function () {
        if (!window.StoryblokBridge) {
          setTimeout(waitForStoryblokToLoad, 100)
        } else {
          const { StoryblokBridge } = window
          const storyblokInstance = new StoryblokBridge()

          // Update live preview when Storyblok story changes
          storyblokInstance.on('input', (event) => {
            setPreviewStory(event.story);
          })
        }
      }

      waitForStoryblokToLoad();
    }
  }, []);

  // Conditional that checks whether to show original GraphQL data or the preview data
  const content = previewStory ? previewStory.content : story.content;

  const description = get(
    content,
    'description',
    `${post.name} - Click to read on.`
  )
  const author = get(post, 'content.author', {})
  const categories = get(post, 'content.categories', [])

  const firstResult = get(posts, 'nodes[0]', null)
  const secondResult = get(posts, 'nodes[1]', null)

  const prevPost = previous ? firstResult : null
  const nextPost = next
    ? next && previous
      ? secondResult
      : firstResult
    : null

  const breadcrumbItems = [
    {
      path: '',
      name: 'Home',
    },
    {
      path: 'blog',
      name: 'QuickMail Insights',
    },
    {
      path: post.slug,
      name: post.name,
    },
  ]

  return (
    <>
      <Seo
        title={`${post.name} | QuickMail`}
        description={description}
        url={post.slug}
      >
        <script type="application/ld+json">{`
          {
            "@context": "https://schema.org",
            "@type": "Article",
            "headline": "${post.name}",
            "image": [
              "${content.hero?.filename}"
            ],
            "datePublished": "${post.unformattedPublishedAt}",
            "dateModified": "${post.unformattedModifiedAt}",
            "author": [{
              "@type": "Person",
              "name": "${author?.name}",
              "url": "https://quickmail.com/authors/${author?.slug}"
            }]
          }
        `}</script>
      </Seo>
      <BlogPost
        post={post}
        content={content}
        author={author}
        categories={categories}
        previous={prevPost}
        next={nextPost}
        breadcrumbItems={breadcrumbItems}
      />
    </>
  )
}

export default BlogPostTemplate

export const BlogPostQuery = graphql`
  query BlogPostBySlug(
    $slug: String!
    $nextSlug: String
    $previousSlug: String
  ) {
    post: storyblokEntry(slug: { eq: $slug }) {
      id
      content
      name
      slug
      full_slug
      unformattedPublishedAt: first_published_at
      unformattedModifiedAt: published_at
      published_at: first_published_at(formatString: "MMMM D, YYYY")
      modified_at: published_at(formatString: "MMMM D, YYYY")
      created_at(formatString: "MMMM D, YYYY")
    }
    posts: allStoryblokEntry(
      sort: { order: [DESC], fields: [published_at] }
      filter: {
        field_component: { eq: "post" }
        slug: { in: [$nextSlug, $previousSlug] }
        field_hidden_boolean: { ne: true }
      }
      limit: 2
    ) {
      nodes {
        slug
        name
        content
        published_at(formatString: "MMMM D, YYYY")
      }
    }
  }
`
