import React from 'react'
import { Link } from 'gatsby'
import get from 'lodash/get'

import '@fontsource/open-sans/600.css'

import '../../static/styles/author.css'

export default ({ node }) => {
  const fallbackUrl =
    'https://imgix.cosmicjs.com/bd953860-28ef-11eb-a4ab-931255f2f3f1-officiallogo.png'
  const url = get(node, 'content.avatar.filename', fallbackUrl)
  const name = get(node, 'name', 'QuickMail')
  const slug = get(node, 'slug', 'QuickMail')

  return (
    <Link
      to={`/authors/${slug}`}
      style={{
        display: 'flex',
        alignItems: 'center',
      }}
      className="author-group"
    >
      <img
        src={`${url}/m/80x80/filters:quality(85)`}
        alt="author"
        width={40}
        height={40}
        className="author-avatar"
        loading="lazy"
      />
      <span className="author-name">{name}</span>
      {/* {node.metadata && node.metadata.bio && (
      <span className="author-details d-flex">
        <span style={{ padding: '0 5px' }}>|</span>
        <div dangerouslySetInnerHTML={{ __html: `${node.metadata.bio}` }} />
      </span>
    )} */}
    </Link>
  )
}
